import React, { Component } from 'react';
import { connect } from 'react-redux';

const port = Number(window.env.PORT);

class PaymentVoucher extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render () {
        return(
            <div className="row card">
                XXXXX
            </div>
        )
    }
}


const styles = {

    star: {
        width: 42,
        marginRight: 4
    },

    inputSearch: {
        marginLeft: 10,
        width: 250,
        display: 'inline'
    },

    buttonHeight: {
        height: 36,
        paddingTop:0,
        paddingBottom:0,
        display: 'inline',
        marginTop: '-0.35rem'
    },

    selectFilter: {
        marginTop: 5,
        marginBottom: -8,
        borderRadius:5,
        display: 'inline'
    },

    overflowY: {
        overflow: 'scroll',
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(PaymentVoucher)

